// Podcast Networks - Footer

// ----------------------

// React
import React, { useState, } from 'react';

// Routing via React Router
//import { Link } from 'react-router-dom';

// load semantic-ui components needed for Marketing Landing page layout
import {
  Segment,
  Container,
  Image,
  List,
  Grid,
  Header,
} from 'semantic-ui-react';
//font awesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXTwitter } from '@fortawesome/free-brands-svg-icons'

// NPM
import dayjs from 'dayjs';

// styles
import styles from './footer.module.scss';

// iHeart Logo - Menu
import svg_iHR_Logo_FullColor from '../../../images/iheartradio-logo-fullcolor.svg'
import svg_privacy from './images/privacyoptions.svg'

// ----------------------

export default function Footer () {
  const [currentYear] = useState(dayjs().year());

  return (
    <Segment id="footer" basic vertical style={{ padding: '5em 0em' }} className={styles.footer}>
      <Container textAlign='center'>
        <Grid doubling stackable columns={'equal'} className={'footer-content'}>
          <Grid.Row>
            <Grid.Column computer={5} mobile={16} textAlign={'left'} className={'info-column'}>
              <Image wrapped size={'medium'} src={svg_iHR_Logo_FullColor} alt={'iHeartRadio Logo'} className={'logo-image'} />
              <p> Music, radio and podcasts, all free. Listen online or download the iHeart App.</p>
              <div className={'connect-row'}>
                <Header as='h4' content='CONNECT' />
                <List link horizontal size={'small'}>
                  <List.Item as={'a'} href={'https://www.facebook.com/iheartradio'} target={'_blank'} rel={'noreferrer'} aria-label={'iHeartRadio Facebook Link'}>
                    <List.Icon size={'large'} name='facebook' />
                  </List.Item>
                  <List.Item as={'a'} href={'https://twitter.com/iHeartRadio'} target={'_blank'} rel={'noreferrer'} aria-label={'iHeartRadio twitter Link'}>
                    <FontAwesomeIcon icon={faXTwitter} className={'twitter large icon'} />
                    {/* <List.Icon size={'large'} name='twitter' /> */}
                  </List.Item>
                  <List.Item as={'a'} href={'https://instagram.com/iHeartRadio'} target={'_blank'} rel={'noreferrer'} aria-label={'iHeartRadio instagram Link'}>
                    <List.Icon size={'large'} name='instagram' />
                  </List.Item>
                  <List.Item as={'a'} href={'https://www.youtube.com/user/iHeartRadio'} target={'_blank'} rel={'noreferrer'} aria-label={'iHeartRadio youtube Link'}>
                    <List.Icon size={'large'} name='youtube' />
                  </List.Item>
                </List>
              </div>
            </Grid.Column>
            <Grid.Column textAlign={'left'}>
              <Header as='h4' content='EXPLORE' />
              <List link relaxed>
                <List.Item as='a' href={'https://www.iheart.com/'} target={'_blank'} rel={'noreferrer'} aria-label={'iHeart Link'}>iHeart</List.Item>
                <List.Item as='a' href={'https://www.iheart.com/live/'} target={'_blank'} rel={'noreferrer'} aria-label={'Live Radio Link'}>Live Radio</List.Item>
                <List.Item as='a' href={'https://www.iheart.com/podcast/'} target={'_blank'} rel={'noreferrer'} aria-label={'Podcasts Link'}>Podcasts</List.Item>
                <List.Item as='a' href={'https://www.iheart.com/artist/'}  target={'_blank'} rel={'noreferrer'} aria-label={'Artist Radio Link'}>Artist Radio</List.Item>
                <List.Item as='a' href={'https://www.iheart.com/playlist/'}  target={'_blank'} rel={'noreferrer'} aria-label={'Playlists Link'}>Playlists</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column textAlign={'left'}>
              <Header as='h4' content='EXCLUSIVES' />
              <List link>
                <List.Item as='a' href={'https://www.iheart.com/news/'} target={'_blank'} rel={'noreferrer'} aria-label={'News Link'}>News</List.Item>
                <List.Item as='a' href={'https://www.iheart.com/content/iheartradio-features/'} target={'_blank'} rel={'noreferrer'} aria-label={'Features Link'}>Features</List.Item>
                <List.Item as='a' href={'https://www.iheart.com/content/iheartradio-events/'} target={'_blank'} rel={'noreferrer'} aria-label={'Events Link'}>Events</List.Item>
                <List.Item as='a' href={'https://news.iheart.com/contests/'} target={'_blank'} rel={'noreferrer'} aria-label={'Contests Link'}>Contests</List.Item>
                <List.Item as='a' href={'https://news.iheart.com/photo/'} target={'_blank'} rel={'noreferrer'} aria-label={'Photos Link'}>Photos</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column textAlign={'left'}>
              <Header as='h4' content='INFORMATION' />
              <List link>
                <List.Item as='a' href={'https://www.iheart.com/about/'} target={'_blank'} rel={'noreferrer'} aria-label={'About Link'}>About</List.Item>
                <List.Item as='a' href={'https://www.iheartmedia.com/advertising'} target={'_blank'} rel={'noreferrer'} aria-label={'Advertise Link'}>Advertise</List.Item>
                <List.Item as='a' href={'https://blog.iheart.com/'} target={'_blank'} rel={'noreferrer'} aria-label={'Blog Link'}>Blog</List.Item>
                <List.Item as='a' href={'https://brand.iheart.com/'} target={'_blank'} rel={'noreferrer'} aria-label={'Brand Guidelines Link'}>Brand Guidelines</List.Item>
                <List.Item as='a' href={'https://www.iheart.com/content/general-contesting-guidelines/'} target={'_blank'} rel={'noreferrer'} aria-label={'Contest Guidelines Link'}>Contest Guidelines</List.Item>
                <List.Item as='a' href={'https://www.iheart.com/offers/'} target={'_blank'} rel={'noreferrer'} aria-label={'Subscription Offers Link'}>Subscription Offers</List.Item>
                <List.Item as='a' href={'https://jobs.iheart.com/'} target={'_blank'} rel={'noreferrer'} aria-label={'Jobs Link'}>Jobs</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column textAlign={'left'}>
              <Header as='h4' content='GET THE APP' />
              <List link>
                <List.Item as='a' href={'https://www.iheart.com/apps/#auto'} target={'_blank'} rel={'noreferrer'} aria-label={'Automotive Link'}>Automotive</List.Item>
                <List.Item as='a' href={'https://www.iheart.com/apps/#home'} target={'_blank'} rel={'noreferrer'} aria-label={'Home Link'}>Home</List.Item>
                <List.Item as='a' href={'https://iheart.onelink.me/Ff5B/GetTheApp'} target={'_blank'} rel={'noreferrer'} aria-label={'Mobile Link'}>Mobile</List.Item>
                <List.Item as='a' href={'https://www.iheart.com/apps/#wear'} target={'_blank'} rel={'noreferrer'} aria-label={'Wearables Link'}>Wearables</List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid columns={'equal'} stackable className={'footer-extra-content'}>
          <Grid.Row>
            <Grid.Column width={5} textAlign={'left'} className={'copyright-column'}>
              <p>&copy; {currentYear} iHeartMedia, Inc.</p>
            </Grid.Column>
            <Grid.Column textAlign={'right'} className={'more-info-column'}>
              <List horizontal divided link relaxed size={'small'}>
                <List.Item as='a' href='https://www.iheart.com/help/' target={'_blank'} rel={'noreferrer'} aria-label={'Help Link'}>
                  Help
                </List.Item>
                <List.Item as='a' href='https://www.iheart.com/privacy/' target={'_blank'} rel={'noreferrer'} aria-label={'Privacy Policy Link'}>
                  Privacy Policy
                </List.Item>
                <List.Item as='a' href='https://privacy.iheart.com/' target={'_blank'} rel={'noreferrer'} aria-label={'Your Privacy Choices Link'}>
                  <Image avatar src={svg_privacy} alt={'privacy icon'} style={{ marginRight: '6px', }} />
                  Your Privacy Choices
                </List.Item>
                <List.Item as='a' href='https://www.iheart.com/terms/' target={'_blank'} rel={'noreferrer'} aria-label={'Terms of Use Link'}>
                  Terms of Use
                </List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>            
      </Container>
    </Segment>
  )
}
